import { getLocale } from "./i18n";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import appConstants from "../app.constants";
dayjs.extend(utc)

export function formatAsCurrencyString (value: number, currencyName = appConstants.DEFAULT_CURRENCY_CODE) {
    const format = Intl.NumberFormat(
        getLocale(),
        {
            currency: currencyName,
            style: 'currency'
        }
    )
    return format.format(value)
}

export function formatNumberWithCommas(value: number) {
    return Intl.NumberFormat(getLocale()).format(value);
}