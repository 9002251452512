import dayjs from "dayjs";
import LocaleData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocaleData);
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

export function getMonths () {
    return ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];
}

export function formatDateAsString (date?: string|Date|null, formattingToken = 'DD MMM YYYY') {
    if (!date) return '';
    return dayjs(date).format(formattingToken)
}