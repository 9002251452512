import { getMetaTagValue } from "./utilities";

export default {
    EMPTY_DATE: '0001-01-01T00:00:00',
    EMPTY_GUID: '00000000-0000-0000-0000-000000000000',
    DEFAULT_LANG_ISO_CODE: 'en',
    DEFAULT_CURRENCY_CODE: 'BND',
    CULTURE_HTTP_HEADER_NAME: 'x-culture',
    RECAPTCHA_HEADER_NAME: 'x-grecaptcha',
    REGEX_EMAIL: /^(?:[a-z0-9_-]+(?:\.[a-z0-9_-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/g,
    MODALS_CONTAINER_EL_SELECTOR: '#modals',
    LINKED_IN_CALLBACK_URL: getMetaTagValue('linked-in-callback-url'),
    GOOGLE_CLIENT_ID: getMetaTagValue('google-client-id'),
}